<template>
    <vx-card :title="(this.id ? 'Edit' : 'Create') + ' Simple Stock Transfer Order'">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        <div class="vx-row mb-6" style="width: 50%" v-if="readonly && id">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Code</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="code" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%" v-if="readonly && id">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>External Code</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="externalCode" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Supplier</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <Supplier 
                    :value="this.selectedSupplier" 
                    @selected="(val) => {
                        this.selectedSupplier = val
                    }"
                ></Supplier>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Source Territory</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <Territory :value="this.selectedSrcTerritory" @selected="(val) => {
                    this.selectedSrcTerritory = val
                }"></Territory>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Dest. Territory</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <Territory :value="this.selectedDestTerritory" @selected="(val) => {
                    this.selectedDestTerritory = val
                }"></Territory>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Source Warehouse</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <Warehouse :value="this.selectedSrcWarehouse" @selected="(val) => {
                    this.selectedSrcWarehouse = val
                }"></Warehouse>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Dest. Warehouse</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <Warehouse :value="this.selectedDestWarehouse" @selected="(val) => {
                    this.selectedDestWarehouse = val
                }"></Warehouse>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Req. Delivery Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <Date :value="this.reqDeliveryDate" @selected="(val) => {
                    this.reqDeliveryDate = val
                }" :add-days="1"></Date>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Est. Time Arrived</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <Date :value="this.eta" @selected="(val) => {
                    this.eta = val
                }" :add-days="1"></Date>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Notes</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-textarea v-model="notes" width="100%" />
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="60%">SKU</th>
                            <th width="20%">HU</th>
                            <th width="15%">Qty</th>
                            <th width="5%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(skuLine, index) in skuLines" :key="index" style="padding-bottom;: 5px">
                            <td class="td vs-table--td" style="padding: 5px">
                                <v-select :filterable="false" :options="skuLines[index].option_sku"
                                    v-model="skuLines[index].selected_sku" @input="(val) => { onChangeSku(val, index); }"
                                    @search="(search, loading) => { onSearchSku(search, loading, index); }" />
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <v-select :filterable="false" :options="skuLines[index].option_hu"
                                    v-model="skuLines[index].selected_hu" @input="(val) => { onChangeHu(val, index); }" />
                            </td>
                            <td class="td vs-table--td" style="padding: 5px"><vs-input type="number"
                                    v-model="skuLines[index].qty"></vs-input></td>
                            <td class="td vs-table--td" style="text-align: center">
                                <div class="vx-input-group flex">
                                    <vs-button v-if="skuLines.length > 1" @click.stop="removeRow(index)" size="small"
                                        color="danger" icon-pack="feather" icon="icon-trash" style="margin-right: 5px"
                                        title="Remove Row" />
                                    <vs-button v-if="index == skuLines.length - 1" @click.stop="addRow()" size="small"
                                        color="success" icon-pack="feather" icon="icon-plus" title="Add Row" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    right: 0%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
                    <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleBack">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import _ from "lodash";
import Warehouse from "./component/Warehouse.vue";
import Territory from "./component/Territory.vue";
import Supplier from "./component/Supplier.vue";
import Date from "./component/Date.vue";
import moment from "moment";
import Swal from 'sweetalert2'
import vSelect from "vue-select";

export default {
    components: {
        Warehouse,
        Date,
        vSelect,
        Territory,
        Supplier
    },
    data() {
        return {
            id: null,
            readonly: true,
            code: null,
            externalCode: null,
            selectedSupplier: null,
            selectedSrcTerritory: null,
            selectedDestTerritory: null,
            selectedSrcWarehouse: null,
            selectedDestWarehouse: null,
            reqDeliveryDate: null,
            eta: null,
            notes: null,
            skuLines: [
                {
                    stock_relocation_plan_line_id: 0,
                    option_sku: [],
                    selected_sku: null,
                    option_hu: [],
                    selected_hu: null,
                    qty: 0,
                }
            ],
            file: null,
            fileUrl: '',
        }
    },
    mounted() {
        var now = moment()
        now.add(1, 'days')

        this.reqDeliveryDate = now.toDate()
        this.eta = now.toDate()

        this.id = this.$route.params.id;
        if (this.id) {
            console.log(this.id)
            this.getData()
        }
    },
    methods: {
        getData() {
            this.$vs.loading();
            this.$http
                .get(this.$store.state.outbound.simpleSto.baseUrlPath + "/" + this.id)
                .then((resp) => {
                    console.log(resp)
                    if (resp.code == 200) {
                        this.code = resp.data.code
                        this.externalCode = resp.data.external_code
                        this.selectedSrcWarehouse = resp.data.selected_src_warehouse
                        this.selectedDestWarehouse = resp.data.selected_dest_warehouse
                        this.reqDeliveryDate = moment(resp.data.req_delivery_date).toDate()
                        this.eta = moment(resp.data.eta).toDate()
                        this.notes = resp.data.notes
                        this.skuLines = resp.data.sku_lines
                        this.fileUrl = resp.data.file_url
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        setDefault() {
            this.id = null
            this.code = null
            this.selectedSrcWarehouse = null
            this.selectedDestWarehouse = null
            this.reqDeliveryDate = null
            this.eta = null
            this.notes = null
            this.skuLines = [
                {
                    option_sku: [],
                    selected_sku: null,
                    option_hu: [],
                    selected_hu: null,
                    qty: 0,
                }
            ]
            this.file = null
        },
        handleBack() {
            this.$router.push({
                name: this.$store.state.outbound.simpleSto.baseRouterName,
            });
        },
        handleChangeAttachment(event) {
            this.file = event.target.files ? event.target.files[0] : null;
        },
        handleSubmit() {
            if (!this.selectedSrcWarehouse) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Source Warehouse is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (!this.selectedDestWarehouse) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Dest. Warehouse is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (this.selectedSrcWarehouse.id == this.selectedDestWarehouse.id) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Source and destination warehouase is required to different",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (!this.reqDeliveryDate) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Req. Delivery Date is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (!this.eta) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Est. Time Arrived is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            if (!this.skuLines.length) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Est. Time Arrived is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            let message = ""
            let skus = []
            this.skuLines.forEach((val) => {
                if (!val.selected_sku) {
                    message = "please check sku, sku is required"
                    return
                }

                if (val.selected_hu == null || val.selected_hu == "") {
                    message = "please check hu, hu is required"
                    return
                }

                if (val.qty < 1) {
                    message = "qty is required more than zero"
                    return
                }

                skus.push({
                    stock_relocation_plan_line_id: val.stock_relocation_plan_line_id,
                    item_id: val.selected_sku.id,
                    item_unit: val.selected_hu,
                    quantity: parseInt(val.qty),
                })
            })

            if (message != "") {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            let formData = new FormData();
            formData.append("file", this.file);
            formData.append("from_warehouse_id", this.selectedSrcWarehouse.id)
            formData.append("to_warehouse_id", this.selectedDestWarehouse.id)
            formData.append("req_delivery_date", this.reqDeliveryDate
                ? moment(this.reqDeliveryDate).format("YYYY-MM-DD")
                : null)
            formData.append("eta", this.eta
                ? moment(this.eta).format("YYYY-MM-DD")
                : null)
            formData.append("notes", (this.notes) ? this.notes : "")
            formData.append("skus", JSON.stringify(skus))

            this.$vs.loading();

            this.$http.post(this.$store.state.outbound.simpleSto.baseUrlPath + "/check-stock", formData)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.$vs.loading.close();
                        Swal.fire({
                            title: 'All stock available!',
                            text: "Are you sure want to create plan?",
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'PLAN'
                        }).then((result) => {
                            if(result.isConfirmed) {
                                this.$vs.loading();
                                if (this.id) {
                                    this.$http
                                        .put(this.$store.state.outbound.simpleSto.baseUrlPath + "/" + this.id, formData)
                                        .then(resp => {
                                            if (resp.code == 200) {
                                                this.$vs.loading.close();
                                                Swal.fire(
                                                    'Done!',
                                                    'Plan Document has been updated.',
                                                    'success'
                                                )
                                                this.draw++;
                                                this.setDefault();
                                                this.handleBack()
                                            } else {
                                                this.$vs.loading.close();
                                                Swal.fire(
                                                    'Something gone wrong', 
                                                    'ERROR : UNKNOWN', 
                                                    'info'
                                                )
                                            }
                                        });
                                } else {
                                    this.$http
                                        .post(this.$store.state.outbound.simpleSto.baseUrlPath, formData)
                                        .then(resp => {
                                            if (resp.code == 200) {
                                                this.$vs.loading.close();
                                                Swal.fire(
                                                    'Done!',
                                                    'Plan Document has been created.',
                                                    'success'
                                                )
                                                this.draw++;
                                                this.setDefault();
                                                this.handleBack()
                                            } else {
                                                this.$vs.loading.close();
                                                Swal.fire(
                                                    'Something gone wrong', 
                                                    'ERROR : UNKNOWN', 
                                                    'info'
                                                )
                                            }
                                        });
                                }
                            }
                        })
                    } else {
                        this.$vs.loading.close();
                        Swal.fire({
                            title: 'Stock is less than required!',
                            text: resp.message,
                            icon: 'error',
                        })
                    }
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        onChangeSku(val, index) {
            this.skuLines[index].selected_sku = val
            this.skuLines[index].option_hu = JSON.parse(val.units)
        },
        onSearchSku(search, loading, index) {
            if (search.length) {
                loading(true);
                this.searchSku(loading, search, this, index);
            }
        },
        searchSku: _.debounce(
            (loading, search, t, index) => {
                if (t.selectedSrcWarehouse && t.selectedSupplier) {
                    t.$http
                        .get("/api/wms/v1/master/warehouse-items", {
                            params: {
                                length: 10,
                                warehouse_id: t.selectedSrcWarehouse.id,
                                supplier_id: t.selectedSupplier.id,
                                search: search,
                            },
                        })
                        .then((resp) => {
                            if (resp.code == 200) {
                                if (resp.data.records) {
                                    resp.data.records.map(function (x) {
                                        return (x.label = x.sku_code + " " + x.name);
                                    });
                                    t.skuLines[index].option_sku = resp.data.records
                                } else {
                                    t.skuLines[index].option_sku = [];
                                }
                                loading(false);
                            } else {
                                t.skuLines[index].option_sku = [];
                                loading(false);
                            }
                        });
                } else {
                    loading(false);
                }
            }
        ),
        onChangeHu(val, index) {
            this.skuLines[index].selected_hu = val
        },
        removeRow(index) {
            this.skuLines.splice(index, 1);
        },
        addRow() {
            this.skuLines.push({
                stock_relocation_plan_line_id: 0,
                option_sku: [],
                selected_sku: null,
                option_hu: [],
                selected_hu: null,
                qty: 0,
            });
        },
        openTab(uri) {
            window.open(uri)
        }
    },
    watch: {
        "selected_src_warehouse": {
            handler(val) {
                this.skuLines.forEach((skuLine) => {
                    skuLine.option_sku = []
                    skuLine.selected_sku = null
                    skuLine.option_hu = []
                    skuLine.selected_hu = []
                    skuLine.qty = 0
                })
            },
            deep: true
        }
    }
}
</script>